.gm-style .gm-style-iw {
  background-color: rgba(52, 50, 50, 0.78);
  border-radius: 5px;
  border: 1px solid #ff8f0d;
  color: #ffffff;
  padding: 25px 0 !important;
  max-height: 300px !important;
}

/* close button */
.gm-style .gm-style-iw [type='button'] {
  background-color: #ffffff !important;
  border-radius: 50% !important;
  width: 22px !important;
  height: 22px !important;
  top: 5px !important;
  right: 5px !important;
}

.gm-style .gm-style-iw [type='button'] span {
  margin: 4px !important;
}
/* close button end */

/* little arrow at bottom */
.gm-style .gm-style-iw-tc::after {
  background-color: #ff8f0d;
}
/* little arrow at bottom end */

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

/* custom marker style */
.zone-marker-label {
  font-size: 40px !important;
  font-weight: 700;
  color: #38e053 !important;
  margin-top: -80px;
}

/* custom window info styles */
.worker-info-window {
  margin: 0 25px;
}

.worker-info-window .info-window-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.worker-info-window .info-window-icons-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 12px;
  padding-bottom: 8px;
  border-top: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  display: flex;
  justify-content: space-between;
}

.worker-info-window .info-window-icon {
  max-height: 42px;
  text-align: center;
}

.worker-info-window .info-window-icon img {
  max-height: 100%;
}

.worker-info-window .info-window-date {
  font-size: 18px;
  font-weight: 700;
}
